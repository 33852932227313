/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



      var header = $(window).height() / 4;
      var menu = $('.sitenav');

      $(window).scroll(function() {

          if ( $(document).scrollTop() > header ) {

              $('.sitenav').addClass('pastHeader');
                // alert(header);

          } else {
              
               $('.sitenav').removeClass('pastHeader');

          }
      });

      $('#projectsAjax').find('.categories').each(function(){
        var type = $(this).text();
        $(this).on('click', function(e){
          var datatype = $(this).text().toLowerCase();
          $('.projectsshowcase').find('.featured').show(800)
          return false;
          e.preventDefault();

        })
      });



      $('.project').each(function(){
        var link = $(this).find('a').attr('href')
        var project = $(this);

        project.click(function(){
          window.location = link;
        })

      })



      $('#projectsAjax').find('a').each(function(){

        var type = $(this).text();
        $(this).on('click', function(e){

          var datatype = $(this).text().toLowerCase();

          // alert(datatype);

          $('.projectsshowcase').find('.featured').hide(400)
          $('.projectsshowcase').find('.workshowcase.'+datatype).show(200);

          return false;
          e.preventDefault();

        })
      });


    
$(function () {
    $(".clientlogos > div").slice(0, 8).addClass('display');
    $("#loadMore").on('click', function (e) {
        e.preventDefault();
        $(".clientlogos > div:hidden").slice(0, 4).addClass('display');
        if ($(".clientlogos > div:hidden").length == 0) {
            $("#load").fadeOut('slow');
        }
        // $('html,body').animate({
        //     scrollTop: $(this).offset().top
        // }, 1500);
    });
});


$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
        $('.totop a').fadeIn();
    } else {
        $('.totop a').fadeOut();
    }
});


    // size_li = $(".clientlogos > div").size();
    // x=3;
    // $('.clientlogos > div:lt('+x+')').show();
    // $('#loadMore').click(function () {
    //     x= (x+5 <= size_li) ? x+5 : size_li;
    //     $('clientlogos > div:lt('+x+')').show();
    // });
    // $('#showLess').click(function () {
    //     x=(x-5<0) ? 3 : x-5;
    //     $('clientlogos > div').not(':lt('+x+')').hide();
    // });

        


      



// lets find items wider than viewport
      var docWidth = document.documentElement.offsetWidth;

      [].forEach.call(
        document.querySelectorAll('*'),
        function(el) {
          if (el.offsetWidth > docWidth) {
            console.log(el);
          }
        }
      );



        $('.owl-carousel').owlCarousel({
            nav: false, // Show next and prev buttons 
            items: 1,
            smartSpeed: 3000,
            dotsSpeed: 1000,
            dragEndSpeed: 1000,
            singleItem: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            pagination: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: false,
            loop: true,
            afterAction: function (el) {
                //remove class active
                this
                    .$owlItems
                    .removeClass('active')

                //add class active
                this
                    .$owlItems //owl internal $ object containing items
                    .eq(this.currentItem + 1)
                    .addClass('active')
            }
        });
     


        $(".hamburger").click(function() {
            $(this).toggleClass("is-active");
            $('#mobilenav').toggleClass("is-active");
            $('.mobilelogo').toggleClass("is-active")
            $('header.navigation').toggleClass('noshadow');
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });

       


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

       

        // featured image height homepage
        function featuredimageheight() {

          var mobile =        "767";
          var tablet =        "991";
          var desktop =       "1199";
          var largedesktop =  "1200";
          var vp = $(window).width();

          if (vp > mobile) {

            $('.featuredrows').each(function(){
              var ht = $(this).find('.measure').outerHeight() + 80;

              $(this).find('.bgimage').css({
                'height' : ht + 'px',
               
              })
            });
            
          }

        }


        // meet the team profile cards
       
       

        $(document).ready(function(){


        })
       

        $(window).resize(function(){
         
        })




        $(document).ready(function(){

         


        })





// var $cog = $('#robbValuation'),
// $body = $(document.body),
// bodyHeight = $body.height();

// $(window).scroll(function () {
//     $cog.css({
//         'transform': 'rotate(' + ($body.scrollTop() / bodyHeight * 360) + 'deg)'
//     });
// });









$.fn.isOnScreen = function(){

    var win = $(window);

    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};


$(document).ready(function(){


    $(window).scroll(function(){

      $('.fullwidth.slidemein').each(function(e){

        if ( $(this).isOnScreen() ) {

            $(this).addClass('slideIn').removeClass('hiderow')

        } else {

            $(this).removeClass('slideIn').addClass('hiderow')

        }


      })


        
    });
});







  






      }
    },
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(document).ready(function(){
          // alert('hello world')
        })
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'login': {
      init: function() {
        // JavaScript to be fired on the about us page







      }

      


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        
      
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
